import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content, Process, Contact } from "page_components/secure-credit"

const Support = ({ location }) => {
  return (
    <Layout
      location={location}
      seo={{
        title: "Sprawdzony deweloper",
        description:
          "Liczne realizacje, zadowoleni Klienci, projekty mieszkaniowe i komercyjne w całej Polsce. Poznaj Trust Investment S.A. - sprawdzonego dewelopera.",
      }}
    >
      <Breadcrumbs title="Bezpieczny kredyt 2%" />
      <PageHeader title="Zaufanie procentuje" />
      <Content />
      <Process />
      <Contact />
    </Layout>
  )
}

export default Support
